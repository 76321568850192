import { render, staticRenderFns } from "./bank_form_create.vue?vue&type=template&id=5730b605&"
import script from "./bank_form_create.vue?vue&type=script&lang=js&"
export * from "./bank_form_create.vue?vue&type=script&lang=js&"
import style0 from "./bank_form_create.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports